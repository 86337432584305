import { UsersPayload } from '@/types';
import axios from 'axios';

export default {
  getAll: () => {
    return axios.get('/users');
  },
  create: (payload: UsersPayload) => {
    return axios.post('/users/create', payload);
  },
  update: (id: number, payload: UsersPayload) => {
    return axios.put(`/users/update/${id}`, payload);
  },
  restore: (id: number) => {
    return axios.put(`/users/restore/${id}`);
  },
  show: (id: number) => {
    return axios.get(`users/${id}`);
  },
  archive: (id: number) => {
    return axios.delete(`/users/archive/${id}`);
  },

  // Company
  getCompanyUsers: (companyId: number) => {
    return axios.get(`/users/company/${companyId}`);
  },
};
