import axios from 'axios';
import { CompanyPayload } from '@/types';

export default {
  getAll: () => {
    return axios.get('/companies');
  },
  getNotActivve: () => {
    return axios.get('/companies/not-active');
  },
  show: (id: number) => {
    return axios.get(`/companies/${id}`);
  },
  create: (payload: CompanyPayload) => {
    return axios.post('/companies/create', payload);
  },
  update(id: number, payload: CompanyPayload) {
    return axios.put(`/companies/update/${id}`, payload);
  },
};
