import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

// LAYOUTS
import AuthLayout from '@/layouts/AuthLayout.vue';
import PublicLayout from '@/layouts/PublicLayout.vue';

import authService from '@/shared/services/auth';
import { setPageTitle } from '@/shared/helpers';
import useAuth from '@/store/modules/auth';
import i18n from '@/i18n';
import { RoleEnum } from '@/enums/RoleEnum';
import VueGates from 'vue-gates';
import { PermissionEnum } from '@/enums/PermissionEnum';

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  // =============================================================================
  // PUBLIC ROUTES
  // =============================================================================
  {
    path: '',
    component: PublicLayout,
    children: [
      {
        path: '/',
        redirect: '/login',
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('../pages/LoginPage.vue'),
      },
      {
        path: '/register-company',
        name: 'register-company',
        component: () => import('../pages/auth/RegisterCompanyPage.vue'),
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../pages/ForgotPasswordPage.vue'),
      },
      {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: () => import('../pages/ResetPasswordPage.vue'),
      },
    ],
  },

  // =============================================================================
  // AUTHENTICATED ROUTES
  // =============================================================================
  {
    path: '',
    component: AuthLayout,
    children: [
      {
        path: '/',
        redirect: () => {
          return authService.getDefaultLoginRoute();
        },
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/pages/DashboardPage.vue'),
        meta: {
          isSuperAdmin: false,
          isCompanyView: true,
          auth: true,
          gate: PermissionEnum.MENU_DASHBOARD,
          title: i18n.t('shared.menu.homepage'),
          linkText: i18n.t('shared.menu.homepage'),
          shouldShowInSidebar: true,
          linkTitle: i18n.t('shared.menu.homepage'),
          iconClass: 'fas fa-fw fa-tachometer-alt',
        },
      },

      // ========== SUPER ADMIN ROUTES START ==========
      {
        path: '/super-admin/auth/edit-password',
        name: 'super-admin-auth-edit-password',
        component: () => import('@/pages/auth/EditPasswordPage.vue'),
        meta: {
          isSuperAdmin: true,
          isCompanyView: false,
          auth: true,
          shouldShowInSidebar: false,
        },
      },
      {
        path: '/super-admin/sitters',
        name: 'super-admin-sitters',
        component: () => import('@/pages/super-admin/SittersPage.vue'),
        redirect: '/super-admin/sitters/list',
        meta: {
          isSuperAdmin: true,
          isCompanyView: false,
          auth: true,
          title: i18n.t('shared.menu.sitters'),
          shouldShowInSidebar: true,
          linkText: i18n.t('shared.menu.sitters'),
          iconClass: 'fas fa-fw fa-female',
        },
        children: [
          {
            path: '/super-admin/sitters/list',
            name: 'super-admin-sitters-list',
            component: () => import('@/pages/super-admin/sitters/SittersListPage.vue'),
            meta: {
              isSuperAdmin: true,
              isCompanyView: false,
              gate: PermissionEnum.MENU_SITTERS_ACTIVE,
              auth: true,
              title: i18n.t('shared.menu.super_admin.sitters.list'),
              linkText: i18n.t('shared.menu.super_admin.sitters.list'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
          {
            path: '/super-admin/sitters/archive',
            name: 'super-admin-sitters-archive',
            component: () => import('@/pages/super-admin/sitters/SittersArchivePage.vue'),
            meta: {
              isSuperAdmin: true,
              isCompanyView: false,
              gate: PermissionEnum.MENU_SITTERS_ARCHIVE,
              auth: true,
              title: i18n.t('shared.menu.super_admin.sitters.archive'),
              linkText: i18n.t('shared.menu.super_admin.sitters.archive'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
        ],
      },
      {
        path: '/super-admin/clients',
        name: 'super-admin-clients',
        component: () => import('@/pages/super-admin/ClientsPage.vue'),
        redirect: '/super-admin/clients/active',
        meta: {
          isSuperAdmin: true,
          isCompanyView: false,
          gate: PermissionEnum.MENU_CLIENTS_ACTIVE,
          auth: true,
          title: i18n.t('shared.menu.clients'),
          shouldShowInSidebar: true,
          linkText: i18n.t('shared.menu.clients'),
          iconClass: 'fas fa-fw fa-female',
        },
        children: [
          {
            path: '/super-admin/clients/active',
            name: 'super-admin-clients-list',
            component: () => import('@/pages/super-admin/clients/ClientsActivePage.vue'),
            meta: {
              isSuperAdmin: true,
              isCompanyView: false,
              gate: PermissionEnum.MENU_CLIENTS_ACTIVE,
              auth: true,
              title: i18n.t('shared.menu.super_admin.clients.list'),
              linkText: i18n.t('shared.menu.super_admin.clients.list'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
          {
            path: '/super-admin/clients/archive',
            name: 'super-admin-clients-archive',
            component: () => import('@/pages/super-admin/clients/ClientsArchivePage.vue'),
            meta: {
              isSuperAdmin: true,
              isCompanyView: false,
              gate: PermissionEnum.MENU_CLIENTS_ARCHIVED_LIST,
              auth: true,
              title: i18n.t('shared.menu.super_admin.clients.archive'),
              linkText: i18n.t('shared.menu.super_admin.clients.archive'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
        ],
      },
      {
        path: '/super-admin/companies',
        name: 'super-admin-companies',
        component: () => import('@/pages/super-admin/CompaniesPage.vue'),
        meta: {
          isSuperAdmin: true,
          isCompanyView: false,
          gate: PermissionEnum.MENU_COMPANIES_ACTIVE,
          auth: true,
          title: i18n.t('shared.menu.super_admin.companies.title'),
          linkText: i18n.t('shared.menu.super_admin.companies.title'),
          shouldShowInSidebar: true,
          linkTitle: i18n.t('shared.menu.super_admin.companies.title'),
          iconClass: 'fas fa-fw fa-suitcase',
        },
        children: [
          {
            path: '/super-admin/companies/list',
            name: 'super-admin-companies-list',
            component: () => import('@/pages/super-admin/companies/CompanyListPage.vue'),
            meta: {
              isSuperAdmin: true,
              isCompanyView: false,
              gate: PermissionEnum.MENU_COMPANIES_ACTIVE,
              auth: true,
              title: i18n.t('shared.menu.super_admin.companies.list'),
              linkText: i18n.t('shared.menu.super_admin.companies.list'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
          {
            path: '/super-admin/companies/list-to-activate',
            name: 'super-admin-companies-list-to-activate',
            component: () => import('@/pages/super-admin/companies/CompanyListToActivatePage.vue'),
            meta: {
              isSuperAdmin: true,
              isCompanyView: false,
              gate: PermissionEnum.MENU_COMPANIES_TO_ACTIVATE,
              auth: true,
              title: i18n.t('shared.menu.super_admin.companies.list_to_activate'),
              linkText: i18n.t('shared.menu.super_admin.companies.list_to_activate'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
        ],
      },
      {
        path: '/super-admin/contracts',
        name: 'super-admin-contracts',
        component: () => import('@/pages/super-admin/ContractsPage.vue'),
        meta: {
          isSuperAdmin: true,
          isCompanyView: false,
          auth: true,
          gate: PermissionEnum.MENU_CONTRACTS_LIST,
          title: i18n.t('shared.menu.super_admin.contracts.title'),
          linkText: i18n.t('shared.menu.super_admin.contracts.title'),
          shouldShowInSidebar: true,
          linkTitle: i18n.t('shared.menu.super_admin.contracts.title'),
          iconClass: 'fas fa-fw fa-file-contract',
        },
        children: [
          {
            path: '/super-admin/contracts/list',
            name: 'super-admin-contracts-list',
            component: () => import('@/pages/super-admin/contracts/ContractListPage.vue'),
            meta: {
              isSuperAdmin: true,
              isCompanyView: false,
              gate: PermissionEnum.MENU_CONTRACTS_LIST,
              auth: true,
              title: i18n.t('shared.menu.super_admin.contracts.list_by_company'),
              linkText: i18n.t('shared.menu.super_admin.contracts.list_by_company'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
        ],
      },
      {
        path: '/super-admin/brokers',
        name: 'super-admin-brokers',
        component: () => import('@/pages/super-admin/BrokersPage.vue'),
        meta: {
          isSuperAdmin: true,
          isCompanyView: false,
          auth: true,
          gate: PermissionEnum.MENU_BROKERS_LIST,
          title: i18n.t('shared.menu.super_admin.brokers.title'),
          linkText: i18n.t('shared.menu.super_admin.brokers.title'),
          shouldShowInSidebar: true,
          linkTitle: i18n.t('shared.menu.super_admin.brokers.title'),
          iconClass: 'fas fa-fw fa-user-md',
        },
        children: [
          {
            path: '/super-admin/brokers/list',
            name: 'super-admin-brokers-list',
            component: () => import('@/pages/super-admin/brokers/BrokerListPage.vue'),
            meta: {
              isSuperAdmin: true,
              isCompanyView: false,
              gate: PermissionEnum.MENU_BROKERS_LIST,
              auth: true,
              title: i18n.t('shared.menu.super_admin.brokers.list'),
              linkText: i18n.t('shared.menu.super_admin.brokers.list'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
        ],
      },
      {
        path: '/',
        name: 'account-management',
        component: () => import('@/pages/super-admin/AccountsManagement.vue'),
        meta: {
          isSuperAdmin: true,
          isCompanyView: false,
          gate: PermissionEnum.MENU_ACCOUNTS_MANAGEMENT,
          auth: true,
          title: i18n.t('shared.menu.accounts_management'),
          shouldShowInSidebar: true,
          linkText: i18n.t('shared.menu.accounts_management'),
          iconClass: 'fas fa-users',
        },
        children: [
          {
            path: '/super-admin/users',
            name: 'sa-account-management-users',
            component: () => import('@/pages/super-admin/accountsManagement/UsersPage.vue'),
            meta: {
              isSuperAdmin: true,
              isCompanyView: false,
              gate: PermissionEnum.MENU_ACCOUNTS_MANAGEMENT,
              auth: true,
              title: i18n.t('shared.menu.account_management_submenu.users'),
              linkText: i18n.t('shared.menu.account_management_submenu.users'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
          {
            path: '/super-admin/roles',
            name: 'sa-account-management-roles',
            component: () => import('@/pages/accountsManagement/RolesPage.vue'),
            meta: {
              isSuperAdmin: true,
              isCompanyView: false,
              gate: PermissionEnum.MENU_ACCOUNTS_MANAGEMENT_ROLES,
              auth: true,
              title: i18n.t('shared.menu.account_management_submenu.permissions'),
              linkText: i18n.t('shared.menu.account_management_submenu.permissions'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
          {
            path: '/super-admin/users-logs',
            name: 'sa-account-management-users-logs',
            component: () => import('@/pages/accountsManagement/UsersLogsPage.vue'),
            meta: {
              isSuperAdmin: true,
              isCompanyView: false,
              gate: PermissionEnum.MENU_ACCOUNTS_MANAGEMENT_USERS_LOGS,
              auth: true,
              title: i18n.t('shared.menu.account_management_submenu.users_logs'),
              linkText: i18n.t('shared.menu.account_management_submenu.users_logs'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
        ],
      },
      {
        path: '/super-admin/system',
        name: 'super-admin-system',
        component: () => import('@/pages/super-admin/SystemPage.vue'),
        meta: {
          isSuperAdmin: true,
          isCompanyView: false,
          gate: PermissionEnum.MENU_SYSTEM,
          auth: true,
          title: i18n.t('shared.menu.system'),
          linkText: i18n.t('shared.menu.system'),
          shouldShowInSidebar: true,
          linkTitle: i18n.t('shared.menu.system'),
          iconClass: 'fas fa-cogs',
        },
        children: [
          {
            path: '/super-admin/system/backup',
            name: 'super-admin-system-backup',
            component: () => import('@/pages/super-admin/brokers/BrokerListPage.vue'),
            meta: {
              isSuperAdmin: true,
              isCompanyView: false,
              gate: PermissionEnum.MENU_SYSTEM_BACKUP,
              auth: true,
              title: i18n.t('shared.menu.system_submenu.backup'),
              linkText: i18n.t('shared.menu.system_submenu.backup'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
        ],
      },
      // ========== SUPER ADMIN ROUTES END ==========
      {
        path: '/auth/edit-password',
        name: 'auth-edit-password',
        component: () => import('@/pages/auth/EditPasswordPage.vue'),
        meta: {
          isSuperAdmin: false,
          isCompanyView: true,
          auth: true,
          shouldShowInSidebar: false,
        },
      },
      {
        path: '/sitters',
        name: 'sitters',
        component: () => import('@/pages/SittersPage.vue'),
        redirect: '/sitters/active',
        meta: {
          isSuperAdmin: false,
          isCompanyView: true,
          auth: true,
          gate: `${PermissionEnum.MENU_SITTERS_ACTIVE}|${PermissionEnum.MENU_SITTERS_ARCHIVED_LIST}`,
          title: i18n.t('shared.menu.sitters'),
          shouldShowInSidebar: true,
          linkText: i18n.t('shared.menu.sitters'),
          iconClass: 'fas fa-fw fa-female',
        },
        children: [
          {
            path: '/sitters/active',
            name: 'sitters-active',
            component: () => import('@/pages/sitters/SittersActivePage.vue'),
            meta: {
              isSuperAdmin: false,
              isCompanyView: true,
              auth: true,
              gate: PermissionEnum.MENU_SITTERS_ACTIVE,
              title: i18n.t('shared.menu.sitters_submenu.active'),
              linkText: i18n.t('shared.menu.sitters_submenu.active'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
          {
            path: '/sitters/archived',
            name: 'sitters-archived',
            component: () => import('@/pages/sitters/SittersArchivePage.vue'),
            meta: {
              isSuperAdmin: false,
              isCompanyView: true,

              auth: true,
              gate: PermissionEnum.MENU_SITTERS_ARCHIVED_LIST,
              title: i18n.t('shared.menu.sitters_submenu.archive'),
              linkText: i18n.t('shared.menu.sitters_submenu.archive'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
        ],
      },
      {
        path: '/clients',
        name: 'clients',
        component: () => import('@/pages/ClientsPage.vue'),
        redirect: '/clients/active',
        meta: {
          isSuperAdmin: false,
          isCompanyView: true,
          auth: true,
          gate: `${PermissionEnum.MENU_CLIENTS_ACTIVE}|${PermissionEnum.MENU_CLIENTS_ARCHIVED_LIST}`,
          title: i18n.t('shared.menu.clients'),
          shouldShowInSidebar: true,
          linkText: i18n.t('shared.menu.clients'),
          iconClass: 'fas fa-fw fa-suitcase',
        },
        children: [
          {
            path: '/clients/active',
            name: 'clients-active',
            component: () => import('@/pages/clients/ClientsActivePage.vue'),
            meta: {
              isSuperAdmin: false,
              isCompanyView: true,
              auth: true,
              gate: PermissionEnum.MENU_CLIENTS_ACTIVE,
              title: i18n.t('shared.menu.clients_submenu.active'),
              linkText: i18n.t('shared.menu.clients_submenu.active'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
          {
            path: '/clients/archived',
            name: 'clients-archived',
            component: () => import('@/pages/clients/ClientsArchivePage.vue'),
            meta: {
              isSuperAdmin: false,
              isCompanyView: true,
              auth: true,
              gate: PermissionEnum.MENU_CLIENTS_ARCHIVED_LIST,
              title: i18n.t('shared.menu.clients_submenu.archive'),
              linkText: i18n.t('shared.menu.clients_submenu.archive'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
        ],
      },
      {
        path: '/brokers',
        name: 'brokers',
        component: () => import('@/pages/BrokersPage.vue'),
        meta: {
          isSuperAdmin: false,
          isCompanyView: true,
          auth: true,
          gate: PermissionEnum.MENU_BROKERS_LIST,
          title: i18n.t('shared.menu.brokers'),
          linkText: i18n.t('shared.menu.brokers'),
          shouldShowInSidebar: true,
          linkTitle: i18n.t('shared.menu.brokers'),
          iconClass: 'fas fa-fw fa-user-md',
        },
      },
      {
        path: '/contracts',
        name: 'contracts',
        component: () => import('@/pages/ContractsPage.vue'),
        meta: {
          isSuperAdmin: false,
          isCompanyView: true,
          auth: true,
          gate: PermissionEnum.MENU_CONTRACTS_LIST,
          title: i18n.t('shared.menu.contracts'),
          linkText: i18n.t('shared.menu.contracts'),
          shouldShowInSidebar: true,
          linkTitle: i18n.t('shared.menu.contracts'),
          iconClass: 'fas fa-fw fa-file-contract',
        },
      },
      {
        path: '/reports',
        name: 'reports',
        component: () => import('@/pages/ReportsPage.vue'),
        redirect: '/reports',
        meta: {
          isSuperAdmin: false,
          isCompanyView: true,
          auth: true,
          gate: PermissionEnum.MENU_REPORTS,
          title: i18n.t('shared.menu.reports'),
          shouldShowInSidebar: true,
          linkText: i18n.t('shared.menu.reports'),
          iconClass: 'fas fa-chart-line',
        },
        children: [
          {
            path: '/reports',
            name: 'reports-active',
            component: () => import('@/pages/reports/ReportsPage.vue'),
            meta: {
              isSuperAdmin: false,
              isCompanyView: true,
              auth: true,
              gate: PermissionEnum.MENU_REPORTS,
              title: i18n.t('shared.menu.reports_submenu.activity'),
              linkText: i18n.t('shared.menu.reports_submenu.activity'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
          {
            path: '/activity-reports',
            name: 'activity-reports',
            component: () => import('@/pages/reports/ActivityReportsPage.vue'),
            meta: {
              isSuperAdmin: false,
              isCompanyView: true,
              auth: true,
              gate: PermissionEnum.REPORTS_OTHERS,
              title: i18n.t('shared.menu.reports_submenu.others'),
              linkText: i18n.t('shared.menu.reports_submenu.others'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
        ],
      },
      {
        path: '/users',
        name: 'company-account-management',
        component: () => import('@/pages/AccountsManagement.vue'),
        meta: {
          isSuperAdmin: false,
          isCompanyView: true,
          auth: true,
          gate: PermissionEnum.MENU_ACCOUNTS_MANAGEMENT,
          title: i18n.t('shared.menu.accounts_management'),
          shouldShowInSidebar: true,
          linkText: i18n.t('shared.menu.accounts_management'),
          iconClass: 'fas fa-users',
        },
        children: [
          {
            path: '/users',
            name: 'account-management-users',
            component: () => import('@/pages/accountsManagement/UsersPage.vue'),
            meta: {
              isSuperAdmin: false,
              isCompanyView: true,
              auth: true,
              gate: PermissionEnum.MENU_USERS_LIST,
              title: i18n.t('shared.menu.account_management_submenu.users'),
              linkText: i18n.t('shared.menu.account_management_submenu.users'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
        ],
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import('@/pages/SettingsPage.vue'),
        meta: {
          isSuperAdmin: false,
          isCompanyView: true,
          auth: true,
          gate: `${PermissionEnum.MENU_CONFIG_RATES}|${PermissionEnum.MENU_CONFIG_COMPANY}|${PermissionEnum.MENU_CONFIG_COUNTRIES}`,
          title: i18n.t('shared.menu.config'),
          shouldShowInSidebar: authService.getUser()?.role !== RoleEnum.SUPER_ADMIN,
          linkText: i18n.t('shared.menu.config'),
          iconClass: 'fas fa-users',
        },
        children: [
          {
            path: '/settings/rates',
            name: 'settings-rates',
            component: () => import('@/pages/settings/RatesPage.vue'),
            meta: {
              isSuperAdmin: false,
              isCompanyView: true,
              auth: true,
              gate: PermissionEnum.MENU_CONFIG_RATES,
              title: i18n.t('shared.menu.config_submenu.settings'),
              linkText: i18n.t('shared.menu.config_submenu.settings'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
          {
            path: '/settings/companies',
            name: 'settings-companies',
            component: () => import('@/pages/settings/CompaniesPage.vue'),
            meta: {
              isSuperAdmin: false,
              isCompanyView: true,
              auth: true,
              gate: PermissionEnum.MENU_CONFIG_COMPANY,
              title: i18n.t('shared.menu.config_submenu.company'),
              linkText: i18n.t('shared.menu.config_submenu.company'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
          {
            path: '/settings/countries',
            name: 'settings-countries',
            component: () => import('@/pages/settings/CountriesPage.vue'),
            meta: {
              isSuperAdmin: false,
              isCompanyView: true,
              auth: true,
              gate: PermissionEnum.MENU_CONFIG_COUNTRIES,
              title: i18n.t('shared.menu.config_submenu.countries'),
              linkText: i18n.t('shared.menu.config_submenu.countries'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
          {
            path: '/settings/transport',
            name: 'settings-transports',
            component: () => import('@/pages/settings/TransportCompaniesPage.vue'),
            meta: {
              isSuperAdmin: false,
              isCompanyView: true,
              auth: true,
              gate: PermissionEnum.MENU_CONFIG_TRANSPORT,
              title: i18n.t('shared.menu.config_submenu.transport_company'),
              linkText: i18n.t('shared.menu.config_submenu.transport_company'),
              iconClass: 'far fa-fw fa-circle',
            },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuth();

  if (!to.matched.length) {
    return next({ path: authService.getDefaultLoginRoute() });
  }

  if (
    (to.name === 'login' || to.path === '/' || to.name === 'forgot-password' || to.name === 'reset-password') &&
    (authService.isLocalStorageFilled() || authStore.isAuthenticated)
  ) {
    authStore.$patch({
      isAuthenticated: true,
      permissions: authService.getPermissions(),
      user: authService.getUser(),
    });

    const dashboardPage = authStore.user?.role === RoleEnum.SUPER_ADMIN ? 'super-admin-sitters-list' : 'dashboard';

    return next({ name: dashboardPage });
  }

  if (to.matched.some((route) => {
    if (
        route.path.length > 0 &&
        route.path.indexOf("super-admin") !== -1 &&
        Object.keys(route.meta).length > 0 &&
        route.meta?.isSuperAdmin === true &&
        authService.getUser()?.role !== RoleEnum.SUPER_ADMIN
    ) {
       authStore.logout();
       return next({ name: 'login' });
    }
  })) {
    next();
  }

  if (
    to.matched.some(
      (route) =>
        route.meta.auth &&
        (route.meta?.isSuperAdmin === (authStore.user?.role === RoleEnum.SUPER_ADMIN) ||
          route.meta?.isSuperAdmin === (authService.getUser()?.role === RoleEnum.SUPER_ADMIN)) &&
        (route.meta?.isCompanyView === (authStore.user?.role !== RoleEnum.SUPER_ADMIN) ||
          route.meta?.isCompanyView === (authService.getUser()?.role !== RoleEnum.SUPER_ADMIN))
    )
  ) {
    if (!authStore.isAuthenticated && authService.isLocalStorageFilled()) {
      authStore.$patch({
        isAuthenticated: true,
        permissions: authService.getPermissions(),
        user: authService.getUser(),
      });
    }

    if (authStore.isAuthenticated) {
      const userPermissions = authService.getPermissions();

      if (userPermissions) {
        const { gates } = Vue.use(VueGates);
        gates.setPermissions(JSON.parse(userPermissions));
      }

      if (to.meta?.isSuperAdmin === false && authStore.user?.role === RoleEnum.SUPER_ADMIN) {
        return next({ name: 'super-admin-sitters-list' });
      }

      next();
    } else {
      authStore.logout();

      return next({ name: 'login' });
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  setPageTitle(to);
});

export const authenticatedRoutes = routes[1];

export default router;
