import axios from '@/api/axios';
import {
  GetActivityReportLogsPayload,
  GetActivityReportUsersPayload,
  GetConsultantReportPayload,
  GetContractsPerMonthPayload,
} from '@/types';

export default {
  getAvailableReports() {
    return axios.get('/reports/others');
  },
  getActivityReports() {
    return axios.get('/reports/activity');
  },
  getActivityReportUsers(payload: GetActivityReportUsersPayload) {
    return axios.post('/reports/activity/users', payload);
  },
  getActivityReportLogs(payload: GetActivityReportLogsPayload) {
    return axios.post('/reports/activity/logs', payload);
  },
  getConsultantReport(payload: GetConsultantReportPayload) {
    return axios.post('/reports/report/consultants', payload);
  },
  getContractsPerMonth(payload: GetContractsPerMonthPayload) {
    return axios.post('/reports/report/contracts-per-month', payload);
  },
};
