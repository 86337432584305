import axios from '@/api/axios';
import { RegisterPayload, UpdatePasswordPayload, User } from '@/types';

interface AuthRequestData {
  user: User;
  token: string;
  permissions: string[];
  refresh_token: string;
  expired_at: string;
}

interface ResetPasswordData {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
}

export default {
  login: (email: string, password: string) => {
    return axios.post<AuthRequestData>('/login', { email, password });
  },
  logout: () => {
    return axios.post('/logout');
  },
  refreshToken: () => {
    return axios.post<AuthRequestData>('/refresh-token');
  },
  register: (payload: RegisterPayload) => {
    return axios.post('/register', payload);
  },
  updatePassword: (payload: UpdatePasswordPayload) => {
    return axios.post('/update-password', payload);
  },
  resetPassword: (email: string) => {
    return axios.post('/password/email', { email });
  },
  setNewPasswordAfterReset: (userData: ResetPasswordData) => {
    return axios.post('/password/reset', userData);
  },
};
