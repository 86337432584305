import { defineStore } from 'pinia';

import authService from '@/shared/services/auth';
import { User } from '@/types';

interface authState {
  user: User | null;
  permissions: string | null;
  isAuthenticated: boolean;
}

export default defineStore('auth', {
  state: (): authState => {
    return {
      isAuthenticated: false,
      permissions: null,
      user: null,
    };
  },
  actions: {
    logout() {
      this.isAuthenticated = false;
      this.permissions = null;
      this.user = null;

      authService.removeAllData();
    },
  },
});
