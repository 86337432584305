import axios from 'axios';
import { TransportCompanyPayload } from '@/types';

export default {
  getAll() {
    return axios.get('/transport-companies');
  },
  getCarTypes() {
    return axios.get('/transport-companies/car-types');
  },
  show(id: number) {
    return axios.get(`/transport-companies/${id}`);
  },
  create(payload: TransportCompanyPayload) {
    return axios.post('/transport-companies/create', payload);
  },
  update(id: number, payload: TransportCompanyPayload) {
    return axios.put(`/transport-companies/update/${id}`, payload);
  },
  archive(id: number) {
    return axios.delete(`/transport-companies/archive/${id}`);
  },
  restore(id: number) {
    return axios.put(`/transport-companies/restore/${id}`);
  },
  delete(id: number) {
    return axios.delete(`/transport-companies/delete/${id}`);
  },
};
