import Vue from 'vue';
import { Route } from 'vue-router';
import VueGates from 'vue-gates';

import authService from '@/shared/services/auth';
import useAuth from '@/store/modules/auth';
import { authenticatedRoutes } from '@/router';
import { RoleEnum } from '@/enums/RoleEnum';

export const setPageTitle = (to: Route) => {
  const title = process.env.VUE_APP_TITLE;
  const [titleEl] = document.getElementsByTagName('title');
  const hasPageTitle = to.meta && to.meta.title;

  return hasPageTitle ? `${to.meta?.title} | ${title}` : (titleEl.innerHTML = title);
};

export const getRoutesForSidebar = () => {
  const authStore = useAuth();

  const { gates } = Vue.use(VueGates);

  return authenticatedRoutes.children?.filter((route) => {
    let result =
      route.meta?.shouldShowInSidebar &&
      route.meta?.isSuperAdmin === (authStore.user?.role === RoleEnum.SUPER_ADMIN) &&
      route.meta?.isSuperAdmin === (authService.getUser()?.role === RoleEnum.SUPER_ADMIN) &&
      route.meta?.isCompanyView === (authStore.user?.role !== RoleEnum.SUPER_ADMIN) &&
      route.meta?.isCompanyView === (authService.getUser()?.role !== RoleEnum.SUPER_ADMIN);

    if (route.meta?.gate) {
      result = result && gates.hasAnyPermission(route.meta?.gate);
    }

    return result;
  });
};
