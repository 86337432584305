import auth from './modules/auth';
import brokers from './modules/brokers';
import clients from './modules/clients';
import companies from './modules/companies';
import contracts from './modules/contracts';
import countries from './modules/countries';
import dashboard from './modules/dashboard';
import reports from './modules/reports';
import roles from './modules/roles';
import settings from './modules/settings';
import sitters from './modules/sitters';
import superAdmin from './modules/superAdmin';
import system from './modules/system';
import transportCompanies from './modules/transportCompanies';
import users from './modules/users';
import usersLogs from './modules/usersLogs';

export default {
  auth,
  brokers,
  clients,
  companies,
  contracts,
  countries,
  dashboard,
  reports,
  roles,
  settings,
  sitters,
  superAdmin,
  system,
  transportCompanies,
  users,
  usersLogs,
};
