import axios from 'axios';

interface GoogleDriveConfig {
  is_enabled: boolean;
  client_id: string | null;
  client_secret: string | null;
  refresh_token: string | null;
  directory_id: string | null;
}

export default {
  getBackupData: () => {
    return axios.get('/system/backup');
  },
  updateGoogleDriveConfig: (googleDriveConfig: GoogleDriveConfig) => {
    return axios.post(`/system/backup/update-google-drive-config`, googleDriveConfig);
  },
  deleteBackupFile: (backupId: number, filename: string) => {
    return axios.delete(`/system/backup/delete/${backupId}/${filename}`);
  },
};
