import {
  ContractsCheckSitterAvailabilityForContractPayload,
  ContractsCreateContractReservationPayload,
  ContractsFinishContractPayload,
  ContractsHireSitterPayload,
  ContractsPayload,
} from '@/types';
import axios from 'axios';

export default {
  getAll: () => {
    return axios.get('/contracts');
  },
  // TODO o co chodzi?
  getByCompany: (companyId: null | number) => {
    return axios.get(`/contracts/company${companyId ? `/${companyId}` : ''}`);
  },
  delete: (clientId: number) => {
    return axios.delete(`/contracts/delete/${clientId}`);
  },

  // General
  show: (id: number) => {
    return axios.get(`/contracts/general/details/${id}`);
  },
  update: (payload: ContractsPayload) => {
    return axios.post(`/contracts/general/update`, payload);
  },
  getSuggestedContractBrokers: (clientId: number) => {
    return axios.get(`/contracts/general/suggested-contract-bid/${clientId}`);
  },
  checkSitterAvailabilityForContract: (payload: ContractsCheckSitterAvailabilityForContractPayload) => {
    return axios.post(`/contracts/general/check-sitter-availability-for-contract`, payload);
  },
  finishContract: (payload: ContractsFinishContractPayload) => {
    return axios.put(`/contracts/general/finish-contract`, payload);
  },

  // Reservation
  listSittersForReservation: (id: number) => {
    return axios.get(`/contracts/reservation/list-sitters-for-reservation/${id}`);
  },
  getContractInfoForReservation: (reservationId: number) => {
    return axios.get(`/contracts/reservation/get-contract-info-for-reservation/${reservationId}`);
  },
  hireService: (payload: ContractsHireSitterPayload) => {
    return axios.post(`/contracts/reservation/hire-service`, payload);
  },
  deleteSitterFromReservation: (reservationId: number, contractId: number) => {
    return axios.delete(`/contracts/reservation/delete-sitter-from-reservation/${reservationId}/${contractId}`);
  },

  // Search
  getSittersForContract: (clientId: number, contractId: number) => {
    return axios.get(`/contracts/search/get-sitters-for-contract/${clientId}/${contractId}`);
  },
  createContractReservation: (payload: ContractsCreateContractReservationPayload) => {
    return axios.post(`/contracts/search/create-contract-reservation`, payload);
  },

  // Summary
  getContractSummary: (contractId: number) => {
    return axios.get(`/contracts/summary/${contractId}`);
  },
};
