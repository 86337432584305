import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { translations } from '@/i18n/messages'
const messages = Object.assign(translations)

Vue.use(VueI18n);

const { VUE_APP_MAIN_LOCALE, VUE_APP_FALLBACK_LOCALE } = process.env;

export default new VueI18n({
  locale: VUE_APP_MAIN_LOCALE,
  fallbackLocale: VUE_APP_FALLBACK_LOCALE,
  messages: messages,
  //TODO: FOR TESTING PURPOSES - DO NOT USE IN PRODUCTION
  missing: (locale, key) => {
    return `${key}(${locale})`;
  },
});
