<template>
  <!-- SINGLE NAV ITEM -->
  <li v-if="!isTreeLink" @click="collapseTreeview" class="nav-item">
    <router-link :to="route.path" :class="singleTabClass">
      <i :class="route.meta.iconClass"></i>
      <p>{{ route.meta.linkText }}</p>
    </router-link>
  </li>

  <!-- TREE NAV ITEM -->
  <li v-else class="nav-item has-treeview">
    <a :class="singleTabClass">
      <i :class="route.meta.iconClass"></i>
      <p>
        {{ route.meta.linkText }}
        <i class="fas fa-angle-left right"></i>
      </p>
    </a>
    <ul class="nav nav-treeview" style="display: none">
      <li v-for="(currentRoute, index) in route.children" :key="index" class="nav-item">
        <router-link
          v-can="currentRoute.meta.gate"
          :to="currentRoute.path"
          :class="currentRoute.path === $route.path ? 'nav-link active' : 'nav-link'">
          <i :class="currentRoute.meta.iconClass"></i>
          <p>{{ currentRoute.meta.linkText }}</p>
        </router-link>
      </li>
    </ul>
  </li>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'NavItem',
  props: {
    route: {
      type: Object,
      required: true,
    },
  },
  methods: {
    collapseTreeview() {
      const treeViewElements = document.querySelectorAll('.has-treeview');

      Array.from(treeViewElements).forEach((element) => {
        element.classList.remove('menu-open');
        element.querySelector('.nav-treeview').style.display = 'none';
      });
    },
  },

  computed: {
    isTreeLink() {
      if (this.route.children) {
        return this.route.children.length > 0;
      }

      return false;
    },
    singleTabClass() {
      if (this.$route.name && this.$route.name?.indexOf(this.route.name) > -1) {
        return 'nav-link active';
      }

      return this.$route.path === this.route.path ? 'nav-link active' : 'nav-link';
    },
    treeLinkClass() {
      return this.route?.children?.map((route) => route.path).includes(this.$route.path)
        ? 'nav-link active'
        : 'nav-link';
    },
  },
});
</script>

<style scoped lang="scss">
.nav-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  cursor: pointer;
}
</style>
