import axios from '@/api/axios';
import { CountriesPayload } from '@/types';

export default {
  getAll: () => {
    return axios.get('/country');
  },
  getByCompany: (companyId: number) => {
    return axios.get(`/country/company/${companyId}`);
  },
  show: (id: number) => {
    return axios.get(`/country/${id}`);
  },
  create: (payload: CountriesPayload) => {
    return axios.post(`/country`, payload);
  },
  update: (id: number, payload: CountriesPayload) => {
    return axios.put(`/country/${id}`, payload);
  },
  restore: (id: number) => {
    return axios.put(`/country/restore/${id}`);
  },
  archive: (id: number) => {
    return axios.put(`/country/archive/${id}`);
  },
  delete: (id: number) => {
    return axios.delete(`/country/${id}`);
  },
};
