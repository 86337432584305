import { RolesPayload } from '@/types';
import axios from 'axios';

export default {
  getAll: () => {
    return axios.get('/roles');
  },
  show: (id: number) => {
    return axios.get(`/roles/${ id }`);
  },
  update: (id: number, payload: RolesPayload) => {
    return axios.put(`/roles/${ id }`, payload);
  },
};
