import axios from '@/api/axios';
import { BrokerPayload } from '@/types';

export default {
  getAll() {
    return axios.get('/brokers');
  },
  getByCompany(companyId: number) {
    return axios.get(`/brokers/company/${companyId}`);
  },
  create(payload: BrokerPayload) {
    return axios.post('/brokers/create', payload);
  },
  show(id: number) {
    return axios.get(`/brokers/${id}`);
  },
  update(id: number, payload: BrokerPayload) {
    return axios.put(`/brokers/update/${id}`, payload);
  },
  restore: (id: number) => {
    return axios.put(`/brokers/restore/${id}`);
  },
  archive: (id: number) => {
    return axios.delete(`/brokers/archive/${id}`);
  },
  delete: (id: number) => {
    return axios.delete(`/brokers/delete/${id}`);
  },
};
