import axios from '@/api/axios';
import {
  CheckExistanceContractPayload,
  ClientContactReminderPayload,
  ClientContractPayload,
  ClientHistoryPayload,
  ClientPayload
} from '@/types';

export default {
  // Clients
  getAll: () => {
    return axios.get('/clients/active');
  },
  getByCompany(companyId: number) {
    return axios.get(`/clients/active/company/${companyId}`);
  },
  show: (id: number) => {
    return axios.get(`/clients/active/${id}`);
  },
  create: (payload: ClientPayload) => {
    return axios.post('/clients/active/create', payload);
  },
  archive: (id: number) => {
    return axios.delete(`/clients/active/delete/${id}`);
  },
  update: (id: number, payload: ClientPayload) => {
    return axios.put(`/clients/active/update/${id}`, payload);
  },
  getArchived: (companyId?: number) => {
    return axios.get(`/clients/archive${companyId ? `/${companyId}` : ''}`);
  },
  delete: (id: number) => {
    return axios.delete(`/clients/archive/delete/${id}`);
  },
  restore: (id: number) => {
    return axios.post(`/clients/archive/restore/${id}`);
  },

  // Attachments
  getAllAttachments: (clientId: number) => {
    return axios.get(`/clients/attachments/list/${clientId}`);
  },
  deleteAttachment: (id: number) => {
    return axios.delete(`/clients/attachments/delete/${id}`);
  },
  downloadAttachment(id: number) {
    return axios.get(`/clients/attachments/download/${id}`, { responseType: 'blob' });
  },

  // History records
  getHistoryList: (id: number) => {
    return axios.get(`/clients/history/list/${id}`);
  },
  createHistoryRecord: (payload: ClientHistoryPayload) => {
    return axios.post('/clients/history/create', payload);
  },
  deleteHistoryRecord: (id: number) => {
    return axios.delete(`/clients/history/delete/${id}`);
  },

  // Contracts
  getContractList: (clientId: number) => {
    return axios.get(`/clients/contract/list/${clientId}`);
  },
  createContract: (payload: ClientContractPayload) => {
    return axios.post('/clients/contract/create', payload);
  },
  checkExistanceContract: (payload: CheckExistanceContractPayload) => {
    return axios.post('/clients/contract/contract-existence-checker', payload);
  },

  // Contact Reminder
  listContactReminder: (id: number) => {
    return axios.get(`/clients/contact-reminder/${id}`);
  },
  createContactReminder: (payload: ClientContactReminderPayload) => {
    return axios.post('/clients/contact-reminder/', payload);
  },
  completeContactReminder: (id: number) => {
    return axios.put(`/clients/contact-reminder/complete/${id}`);
  },
  deleteContactReminder: (id: number) => {
    return axios.delete(`/clients/contact-reminder/${id}`);
  },
};
