import { RegisterPayload } from '@/types';
import axios from 'axios';

export default {
  registerCompany: (payload: RegisterPayload) => {
    return axios.post('/super-admin/register-company', payload);
  },
  updateCompany: (id: number, payload: RegisterPayload) => {
    return axios.put(`/super-admin/update-company/${id}`, payload);
  }
};
