import { User } from '@/types';

export default {
  setAccessTokens: (token: string, refreshToken: string, expiredAt: string) => {
    localStorage.setItem('accessToken', token);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('expiredAt', expiredAt);
  },
  setUser: (user: User) => {
    localStorage.setItem('user', JSON.stringify(user));
  },
  setPermissions: (permissions: string | null) => {
    if (permissions) {
      localStorage.setItem('permissions', permissions);
    }
  },
  getUser: (): User | null => {
    if (!localStorage.getItem('user')) {
      return null;
    }

    try {
      return JSON.parse(localStorage.getItem('user') as string);
    } catch {
      return null;
    }
  },
  getPermissions: (): string | null => {
    return localStorage.getItem('permissions');
  },
  getAccessToken: () => {
    return localStorage.getItem('accessToken');
  },
  getRefreshToken: () => {
    return localStorage.getItem('refreshToken');
  },
  getExpiredAt() {
    return localStorage.getItem('expiredAt');
  },
  removeAllData: () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiredAt');
    localStorage.removeItem('user');
  },
  getTokenExpiredAt: () => {
    if (!localStorage.getItem('expiredAt')) {
      return null;
    }

    return localStorage.getItem('expiredAt');
  },
  isTokenExpired: function () {
    if (!this.getExpiredAt()) {
      return true;
    }

    const now = new Date(Date.now());
    const tokenExpiry = new Date(localStorage.getItem('expiredAt') as string);

    return now > tokenExpiry;
  },
  isLocalStorageFilled: function () {
    return (
      this.getAccessToken() !== null &&
      this.getRefreshToken() !== null &&
      this.getExpiredAt() !== null &&
      this.getUser() !== null
    );
  },
  getDefaultLoginRoute: function () {
    return this.getUser() ? '/dashboard' : '/login';
  },
};
