import { defineStore } from 'pinia';

interface Company {
  id: number;
  name: string;
  is_active: boolean;
}

interface State {
  company_id: number | null;
  company_name: string | null | undefined;
  companies: Company[];
}

export default defineStore('superAdmin', {
  state: (): State => ({
    company_id: null,
    company_name: null,
    companies: [],
  }),
  actions: {
    updateCompanyId(companyId: number | null) {
      this.company_id = companyId;
    },
    updateCompanyName(companyName: string | null | undefined) {
      this.company_name = companyName;
    },
    updateCompanies(companies: Company[]) {
      this.companies = companies;
    },
    resetCompanyData() {
      this.company_id = null;
      this.company_name = null;
      this.companies = [];
    },
  },
});
