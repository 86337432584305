<template>
  <aside class="main-sidebar sidebar-dark-lightblue elevation-4">
    <!-- BRAND LOGO -->
    <router-link :to="{ name: defaultRoute }" class="brand-link text-center navbar-light">
      <img :src="CarelyLogo" alt="Carely" />
    </router-link>

    <!-- NAVIGATION -->
    <div class="sidebar">
      <nav class="pt-2">
        <ul
          data-widget="treeview"
          role="menu"
          class="nav nav-pills nav-sidebar flex-column nav-child-indent left-menu-handler">
          <nav-link v-for="(route, index) in getRoutesForSidebar()" :key="index" :route="route" />
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import authService from '@/shared/services/auth';
import { getRoutesForSidebar } from '@/shared/helpers';

import NavLink from './NavItem.vue';
import CarelyLogo from '@assets/images/carely-logo-115x25.png';
import { RoleEnum } from '@/enums/RoleEnum';

export default defineComponent({
  name: 'SideBar',
  setup() {
    return {
      CarelyLogo,
      getRoutesForSidebar,
    };
  },
  mounted() {
    $('[data-widget="treeview"]').Treeview('init');
  },
  components: {
    NavLink,
  },
  computed: {
    defaultRoute() {
      return authService.getUser()?.role === RoleEnum.SUPER_ADMIN ? 'super-admin-sitters-list' : 'dashboard';
    },
  },
});
</script>

<style scoped>
.main-sidebar {
  position: fixed !important;
  top: 0;
}
</style>
