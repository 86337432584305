// System
require('./components/shared/EventBus');
import Vue from 'vue';
import pinia from '@/store';
import router from '@/router';
import ToggleButton from 'vue-js-toggle-button';
import VuejsDialog from 'vuejs-dialog';
import VueNumeric from 'vue-numeric';
import VueMask from 'v-mask';
import VueGates from 'vue-gates';

// Components
import App from '@/App.vue';

// Utils
import i18n from '@/i18n';

Vue.config.productionTip = true;

Vue.use(ToggleButton);
Vue.use(VueNumeric);
Vue.use(VueMask);
Vue.use(VuejsDialog);
Vue.use(VueGates);

new Vue({
  pinia,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

($('[data-toggle="tooltip"]') as any).tooltip();
