import axios from '@/api/axios';
import { User, UserGender, LanguageKnowledge } from '@/types';

interface ContactRemindersFilter {
  consultants: Pick<User, 'id' | 'name'>[];
  date_from: string;
  date_to: string;
  limit: string;
}

interface DocumentExpiryFilter {
  days_to_expiry: string | number;
}

interface NewContractsFilter {
  from_filter: boolean;
  gender: UserGender;
  language_knowledge: LanguageKnowledge;
  smoking: boolean;
  drive_license: boolean;
}

interface SittersWithActiveDocsFilter {
  status?: number;
}

export default {
  activeContracts: () => {
    return axios.get('/dashboard/active-contracts');
  },
  sitterContactReminders: () => {
    return axios.get('/dashboard/sitter-contact-reminder');
  },
  sitterContactRemindersFilter: (filters: ContactRemindersFilter) => {
    return axios.get('/dashboard/sitter-contact-reminder', {
      params: filters,
    });
  },
  clientContactReminders: () => {
    return axios.get('/dashboard/client-contact-reminder');
  },
  clientContactRemindersFilter: (filters: ContactRemindersFilter) => {
    return axios.get('/dashboard/client-contact-reminder', {
      params: filters,
    });
  },
  dashboard: () => {
    return axios.get('/dashboard');
  },
  documentExpiry: () => {
    return axios.get('/dashboard/document-expiry');
  },
  documentExpiryFilter: (filters: DocumentExpiryFilter) => {
    return axios.get('/dashboard/document-expiry', {
      params: filters,
    });
  },
  endingContracts: () => {
    return axios.get('/dashboard/ending-contracts');
  },
  newContracts: () => {
    return axios.get('/dashboard/new-contracts');
  },
  newContractsFilter: (filters: NewContractsFilter) => {
    return axios.get('/dashboard/new-contracts', {
      params: filters,
    });
  },
  sittersWithActiveDocs: () => {
    return axios.get('/dashboard/sitters-with-active-docs');
  },
  sittersWithActiveDocsFilter: (filters: SittersWithActiveDocsFilter) => {
    return axios.get('/dashboard/sitters-with-active-docs', {
      params: filters,
    });
  },
  workHoursCounter: () => {
    return axios.get('/dashboard/work-hours-counter');
  },
};
