import axios from 'axios';

import api from '@/api';
import authService from '@/shared/services/auth';
import i18n from '@/i18n';
import useAuth from '@/store/modules/auth';
import toastr from "toastr";
import router from "@/router";

axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api`;
axios.defaults.headers.common = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
};

axios.interceptors.request.use(function (config) {
  config.headers = config.headers ?? {};

  if (config.url === '/refresh-token') {
    config.headers['Authorization'] = `Bearer ${authService.getRefreshToken()}`;

    return config;
  }

  config.headers.Authorization = `Bearer ${authService.getAccessToken()}`;

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const authStore = useAuth();

    if (error) {
      if (error.response && error.response.status === 403) {
        toastr.error(i18n.t('shared.unauthorized').toString());

        return;
      }

      const { config, response } = error;
      const originalRequest = config;

      if (response && response.status === 401) {
        if (config.url === '/refresh-token') {
          authStore.logout();

          return router.push({ name: 'login' }, () => {
            toastr.error(i18n.t('auth.token_expired').toString());
          });
        }

        try {
          const {
            data: { token, user, expired_at: expiredAt, refresh_token: refreshToken },
          } = await api.auth.refreshToken();

          authStore.$patch({
            user,
            isAuthenticated: true,
          });

          authService.setUser(user);
          authService.setAccessTokens(token, refreshToken, expiredAt);

          originalRequest.headers.Authorization = `Bearer ${token}`;

          return Promise.resolve(axios(originalRequest));
        } catch (error) {
          return Promise.reject(error);
        }
      }

      return Promise.reject(error);
    }
  }
);

export default axios;
