import { ClientCareRatesPayload, CompaniesPayload, SitterEmploymentCostsPayload, SitterMonthlyRewardRatesPayload, SitterSettlementRatesPayload } from '@/types';
import axios from 'axios';

export default {
  // Companies
  getCompanies: () => {
    return axios.get(`/settings/companies`);
  },
  updateCompany: (payload: CompaniesPayload) => {
    return axios.post(`/settings/companies/update`, payload);
  },

  // Rates
  getRates: () => {
    return axios.get('/settings/rates');
  },
  updateCareRates: (payload: ClientCareRatesPayload) => {
    return axios.post(`/settings/rates/update/update-care-rates`, payload);
  },
  updateSitterEmploymentCosts: (payload: SitterEmploymentCostsPayload) => {
    return axios.post(`/settings/rates/update/sitter-employment-costs`, payload);
  },
  updateSitterMonthlyRewardRates: (payload: SitterMonthlyRewardRatesPayload) => {
    return axios.post(`/settings/rates/update/sitter-monthly-reward-rates`, payload);
  },
  updateSitterSettlementRates: (payload: SitterSettlementRatesPayload) => {
    return axios.post(`/settings/rates/update/sitter-settlement-rates`, payload);
  },
};
