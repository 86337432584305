export const PermissionEnum = {
  MENU_ACCOUNTS_MANAGEMENT: 'menu.accounts_management',
  MENU_ACCOUNTS_MANAGEMENT_USERS_PERMISSIONS: 'menu.accounts_management.users.permissions',
  MENU_ACCOUNTS_MANAGEMENT_ROLES: 'menu.accounts_management.roles',
  MENU_ACCOUNTS_MANAGEMENT_USERS_LOGS: 'menu.accounts_management.users_logs',

  MENU_BROKERS_LIST: 'menu.brokers.list',
  MENU_BROKERS_CREATE: 'menu.brokers.create',
  MENU_BROKERS_EDIT: 'menu.brokers.edit',
  MENU_BROKERS_ARCHIVE: 'menu.brokers.archive',
  MENU_BROKERS_RESTORE: 'menu.brokers.restore',
  MENU_BROKERS_DELETE: 'menu.brokers.delete',

  MENU_CLIENTS_ACTIVE: 'menu.clients.active',
  MENU_CLIENTS_ARCHIVED_LIST: 'menu.clients.archived_list',
  MENU_CLIENTS_CREATE: 'menu.clients.create',
  MENU_CLIENTS_EDIT: 'menu.clients.edit',
  MENU_CLIENTS_ARCHIVE: 'menu.clients.archive',
  MENU_CLIENTS_RESTORE: 'menu.clients.restore',
  MENU_CLIENTS_DELETE: 'menu.clients.delete',

  MENU_COMPANIES_ACTIVE: 'menu.companies.active',
  MENU_COMPANIES_ARCHIVED_LIST: 'menu.companies.archived_list',
  MENU_COMPANIES_TO_ACTIVATE: 'menu.companies.to_activate',
  MENU_COMPANIES_CREATE: 'menu.companies.create',
  MENU_COMPANIES_UPDATE: 'menu.companies.update',
  MENU_COMPANIES_ARCHIVE: 'menu.companies.archive',
  MENU_COMPANIES_RESTORE: 'menu.companies.restore',
  MENU_COMPANIES_DELETE: 'menu.companies.delete',

  MENU_CONFIG_COMPANY: 'menu.config.company',
  MENU_CONFIG_COUNTRIES: 'menu.config.countries',
  MENU_CONFIG_RATES: 'menu.config.rates',
  MENU_CONFIG_TRANSPORT: 'menu.config.transport',

  MENU_CONTRACTS_LIST: 'menu.contracts.list',
  MENU_CONTRACTS_CREATE: 'menu.contracts.create',
  MENU_CONTRACTS_EDIT: 'menu.contracts.edit',
  MENU_CONTRACTS_DELETE: 'menu.contracts.delete',
  MENU_CONTRACTS_FINISH: 'menu.contracts.finish',

  MENU_DASHBOARD: 'menu.dashboard',

  MENU_REPORTS: 'menu.reports',

  MENU_SITTERS_ACTIVE: 'menu.sitters.active',
  MENU_SITTERS_ARCHIVED_LIST: 'menu.sitters.archived_list',
  MENU_SITTERS_DETAIL: 'menu.sitters.detail',
  MENU_SITTERS_CREATE: 'menu.sitters.create',
  MENU_SITTERS_EDIT: 'menu.sitters.edit',
  MENU_SITTERS_ARCHIVE: 'menu.sitters.archive',
  MENU_SITTERS_RESTORE: 'menu.sitters.restore',
  MENU_SITTERS_ANONYMIZE: 'menu.sitters.anonymize',
  MENU_SITTERS_DELETE: 'menu.sitters.delete',

  MENU_SYSTEM: 'menu.system',
  MENU_SYSTEM_BACKUP: 'menu.system.backup',
  MENU_SYSTEM_LOGS: 'menu.system.logs',

  MENU_TIMELINE: 'menu.timeline',

  REPORTS_CONSULTANTS: 'reports.consultants',
  REPORTS_CONTRACTS_PER_MONTH: 'reports.contracts_per_month',
  REPORTS_OTHERS: 'reports.others',

  MENU_USERS_LIST: 'menu.users.list',
  MENU_USERS_CREATE: 'menu.users.create',
  MENU_USERS_EDIT: 'menu.users.edit',
  MENU_USERS_ARCHIVE: 'menu.users.archive',
  MENU_USERS_RESTORE: 'menu.users.restore',
};

export const SuperAdminPermissionsOnly = [
  PermissionEnum.MENU_COMPANIES_ACTIVE,
  PermissionEnum.MENU_COMPANIES_ARCHIVED_LIST,
  PermissionEnum.MENU_COMPANIES_TO_ACTIVATE,
  PermissionEnum.MENU_COMPANIES_CREATE,
  PermissionEnum.MENU_COMPANIES_UPDATE,
  PermissionEnum.MENU_COMPANIES_ARCHIVE,
  PermissionEnum.MENU_COMPANIES_RESTORE,
  PermissionEnum.MENU_COMPANIES_DELETE,
  PermissionEnum.MENU_SYSTEM,
  PermissionEnum.MENU_SYSTEM_BACKUP,
  PermissionEnum.MENU_SYSTEM_LOGS,
  PermissionEnum.MENU_ACCOUNTS_MANAGEMENT_USERS_LOGS,
];
