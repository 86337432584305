import Vue from 'vue';

/**
 * Placeholder for default Vue event management
 */
window.EventBus = new (class {
  constructor() {
    this.vue = new Vue();
  }

  fire(event, data = null) {
    this.vue.$emit(event, data);
  }

  listen(event, callback) {
    this.vue.$on(event, callback);
  }

  remove(event) {
    this.vue.$off(event);
  }
})();
