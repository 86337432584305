import axios from '@/api/axios';
import {
  SitterDocumentPayload,
  SittersContactHistoryPayload,
  SittersContactReminderPayload,
  SittersPayload,
  SittersTermsUnavailability,
} from '@/types';

interface GenerateCvPayload {
  show_experience: boolean;
  with_firma_from: boolean;
}

export default {
  // Sitters
  listActiveSitters: () => {
    return axios.get('/sitters/active/sitters');
  },
  getByCompany: (companyId: number) => {
    return axios.get(`/sitters/active/sitters/company/${companyId}`);
  },
  create: (payload: SittersPayload) => {
    return axios.post('/sitters/active/sitters/create', payload);
  },
  showActive: (id: number) => {
    return axios.get(`/sitters/active/sitters/details/${id}`);
  },
  update: (id: number, payload: SittersPayload) => {
    return axios.post(`/sitters/active/sitters/update/${id}`, payload);
  },
  generateCv: (id: number, payload: GenerateCvPayload) => {
    return axios.post(`/sitters/active/sitters/generate-cv/${id}`, { params: payload }, { responseType: 'blob' });
  },
  archiveSitter: (id: number) => {
    return axios.delete(`/sitters/active/sitters/delete/${id}`);
  },
  deletePhoto: (id: number) => {
    return axios.delete(`/sitters/active/sitters/photo/delete/${id}`);
  },

  // Contacts
  getContactList: (id: number) => {
    return axios.get(`/sitters/active/history/list/${id}`);
  },
  createContact: (payload: SittersContactHistoryPayload) => {
    return axios.post('/sitters/active/history/create', payload);
  },
  deleteContact: (id: number) => {
    return axios.delete(`/sitters/active/history/delete/${id}`);
  },

  // Term Unavailability
  listTermUnavailability: (id: number) => {
    return axios.get(`/sitters/active/term-unavailability/list/${id}`);
  },
  createTermUnavailability: (payload: SittersTermsUnavailability) => {
    return axios.post('/sitters/active/term-unavailability/create', payload);
  },
  deleteTermUnavailability: (id: number) => {
    return axios.delete(`/sitters/active/term-unavailability/delete/${id}`);
  },

  // Contact Reminder
  listContatReminder: (id: number) => {
    return axios.get(`/sitters/active/remind-sitter-contact/list/${id}`);
  },
  createContactReminder: (payload: SittersContactReminderPayload) => {
    return axios.post('/sitters/active/remind-sitter-contact/create', payload);
  },
  completeContactReminder: (id: number) => {
    return axios.put(`/sitters/active/remind-sitter-contact/complete/${id}`);
  },
  deleteContactReminder: (id: number) => {
    return axios.delete(`/sitters/active/remind-sitter-contact/delete/${id}`);
  },

  // Contact Status
  updateStatus: (id: number) => {
    return axios.post(`/sitters/active/history/update-status/${id}`);
  },

  // Attachments
  listAttachments: (id: number) => {
    return axios.get(`/sitters/active/attachments/list/${id}`);
  },
  deleteAttachment: (id: number) => {
    return axios.delete(`/sitters/active/attachments/delete/${id}`);
  },
  downloadAttachment(id: number) {
    return axios.get(`/sitters/active/attachments/download/${id}`, { responseType: 'blob' });
  },

  // Documents
  updateDocument: (payload: SitterDocumentPayload) => {
    return axios.post('/sitters/active/documents/update-data', payload);
  },
  deleteDocument: (id: number, documentType: number) => {
    return axios.delete(`/sitters/active/documents/delete/${id}/${documentType}`);
  },
  downloadDocument: (id: number, documentType: number) => {
    return axios.get(`/sitters/active/documents/download/${id}/${documentType}`, { responseType: 'blob' });
  },

  // Archive
  getArchiveList: (companyId?: number) => {
    return axios.get(`/sitters/archive/company/${companyId ? `${companyId}` : ''}`);
  },
  showArchived: (id: number) => {
    return axios.get(`/sitters/archive/list/${id}`);
  },
  anonymize: (id: number) => {
    return axios.put(`/sitters/archive/anonymize/${id}`);
  },
  restore: (id: number) => {
    return axios.post(`/sitters/archive/restore/${id}`);
  },
  delete: (id: number) => {
    return axios.delete(`/sitters/archive/delete/${id}`);
  },
};
